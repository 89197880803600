<template>
    <main>
        <page-header-compact>
            Asignaciones de Area  (Fecha: {{asignacionarea.fecha }})
        </page-header-compact>

        <div class="container-fluid">
            <div class="card mt-4">
                <div class="card-body">

                    <data-grid :data="pagination"
                           @changePage="changePage($event)"
                           @perPageChange="perPageChange($event)"
                           @search="search($event)">
                            <template #grid>
                                <grid-table>
                                    <template #head>
                                        <grid-cell-header class="bg-light"  v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                                    </template>
                                    <template #body>
                                        <grid-row v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                            <grid-cell class="text-center small">
                                                {{item.id}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.usuarios.name}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.areas.nombre}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.asignacion_area.fecha}}
                                            </grid-cell>
                                            <grid-cell class="text-center  small">
                                              <button class="btn btn-datatable btn-transparent-dark" @click="printWorksheet(item.areas.id, item.asignacion_area.fecha)">
                                                <i class="fas fa-print fa-fw"></i>
                                              </button>
                                            </grid-cell>
                                        </grid-row>
                                    </template>
                                </grid-table>
                            </template>

                            <template #action-button>
                                <button @click="abrirModalGuardar()" class="btn btn-info btn-sm shadow-sm mb-2" type="button">
                                    Nuevo &nbsp;<i class="fas fa-plus"></i>
                                </button>
                            </template>

                    </data-grid>


                    <modalAsignacionAreasDetalle :id="id_asignacion_area" ref="modalAsignacionAreasDetalle" v-on:cargar-asignacion-areas="getAsignacionAreasDetalle(id_asignacion_area)"></modalAsignacionAreasDetalle>

                </div>
            </div>

        </div>

    </main>
</template>
<script>
import PageHeaderCompact from "../../../../../../../components/layouts/content/page-header-compact";
import DataGrid from "../../../../../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../../../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../../../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../../../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../../../../../components/common/utilities/DataGrid/GridCell";
import Toast from '../../../../../../../components/common/utilities/toast';
import modalAsignacionAreasDetalle from "./modalAsignacionAreasDetalle";
import asignacionAreasService from '../../../../../../../services/asignacionAreasService';
import asignacionAreasDetalleService from '../../../../../../../services/asignacionAreasDetalleService';
import ordenTrabajoService from "@/services/ordenTrabajoService";
import {downloadFile} from "@/utils/utils";
export default {
    components: {
        PageHeaderCompact,GridCell, GridRow, GridCellHeader, GridTable, DataGrid,modalAsignacionAreasDetalle
    },
    data(){
        return {
            asignacionarea:{},
            id_asignacion_area:'',

            asignacionareadetalle:{},

            headers : [
                'Id','Profesional','Area de Laboratorio','Fecha asignada', 'Planilla'
           ],
           pagination : {
               data : [],
               current_page : 1,
               page : 1,
               per_page : 5,
               search : '',
               total: '',
           }
        }
    },
    methods : {
        async getAsignacionArea(id){
            const response = await asignacionAreasService.show(id);
            this.asignacionarea=response.data;
        },
        async getAsignacionAreasDetalle(id){
            try {
                this.LoaderSpinnerShow();
                const response = (await asignacionAreasDetalleService.list(id,this.pagination.search, this.pagination))?.data;
                this.asignacionareadetalle = response.data;
                console.log(response.data);

                this.pagination.data = response.data;
                this.pagination.current_page  = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();

            }catch (e) {
                console.error(e);
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        changePage(page) {
            this.pagination.page = page;
            this.getAsignacionAreasDetalle(this.id_asignacion_area);
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getAsignacionAreasDetalle(this.id_asignacion_area);
        },
        search(query){
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getAsignacionAreasDetalle(this.id_asignacion_area);
        },
        abrirModalGuardar(){
           this.$refs.modalAsignacionAreasDetalle.modalGuardar(this.id_asignacion_area);
        },
        async printWorksheet(areaId, workDate) {
          try {
            this.LoaderSpinnerShow();
              const {data} = await ordenTrabajoService.printWorksheet({
                'areaId' : areaId,
                'workDate' : workDate
              });

              downloadFile(data, 'application/pdf', 'filename.pdf');
            this.LoaderSpinnerHide();
            await Toast.fire({
              icon: 'success',
              title: 'Planilla generada con exito'
            });
          }catch (e) {
            this.LoaderSpinnerHide();
            console.error(e);
            Toast.fire({
              icon: 'error',
              title: 'Ocurrio un error al procesar la solicitud'
            });
          }
        }
    },
    watch : {

    },
    async created(){
        this.id_asignacion_area=this.$route.params.id_asignacion_area;
        await this.getAsignacionArea(this.id_asignacion_area);
        await this.getAsignacionAreasDetalle(this.id_asignacion_area);

    }
}
</script>
